import { FC } from 'react'
import { WrappedInput } from '../../../components/Form'
import { checkBoxLabel, checkBoxLabelDisable, radioOptions } from './consts'
import { WrappedRadioItem } from '../../../Radio'
import { Divider } from '../../../Divider'

import styles from './EarlyRepayment.m.less'
import { Button } from '../../../button'

interface IEarlyRepaymentViewProps {
    isDynamicPrice: boolean
    earlyRepayment: boolean
    isShowRepaymentButton?: boolean
    onCreateApplicationClick?: () => void
}

export const EarlyRepaymentView: FC<IEarlyRepaymentViewProps> = ({
    isDynamicPrice,
    earlyRepayment,
    isShowRepaymentButton,
    onCreateApplicationClick,
}) => {
    return (
        <>
            <Divider />
            <div className={styles.rowWithAction}>
                <div className={styles.row}>
                    <div className={styles.label}>
                        <span>Досрочное погашение</span>
                    </div>
                    <div className={styles.value}>
                        <span>{earlyRepayment ? checkBoxLabel : checkBoxLabelDisable}</span>
                    </div>
                </div>
                {Boolean(isShowRepaymentButton) && (
                    <Button borderRadius={16} onClick={onCreateApplicationClick} type="primary">
                        Погасить досрочно
                    </Button>
                )}
            </div>
            {earlyRepayment && (
                <>
                    <div className="formRow">
                        <WrappedInput
                            label="Срок погашения по обращению"
                            name="period_for_early_repayment"
                            uneditable
                            className="formComponentItem"
                            required
                        />
                    </div>

                    {isDynamicPrice && (
                        <div className="formRow">
                            <WrappedInput
                                label="Спред"
                                name="early_redemption_spread"
                                uneditable
                                className="formComponentItem"
                            />
                        </div>
                    )}
                    <div className="formRow">
                        <div className={styles.clearValue}>
                            <WrappedRadioItem
                                id="early_repayment_type"
                                name="early_repayment_type"
                                uneditable
                                options={radioOptions}
                            />
                        </div>
                    </div>
                </>
            )}
        </>
    )
}
