import {
    ECouponPaymentCollectType,
    IDfaFront,
    TAddDates,
    TCouponPaymentDate,
} from '@dltru/dfa-models'
import { FC, useCallback, useContext, useEffect } from 'react'

import { EarlyRepaymentCreate } from './EarlyRepaymentCreate'
import { EarlyRepaymentView } from './EarlyRepaymentView'

interface IEarlyRepaymentProps {
    uneditable: boolean
    isDynamicPrice: boolean
    earlyRepayment: boolean

    isShowRepaymentButton?: boolean
    onCreateApplicationClick?: () => void
}

export const EarlyRepayment: FC<IEarlyRepaymentProps> = ({
    uneditable,
    isDynamicPrice,
    earlyRepayment,
    isShowRepaymentButton,
    onCreateApplicationClick,
}) => {
    return (
        <>
            {uneditable ? (
                <EarlyRepaymentView
                    isDynamicPrice={isDynamicPrice}
                    earlyRepayment={earlyRepayment}
                    isShowRepaymentButton={isShowRepaymentButton}
                    onCreateApplicationClick={onCreateApplicationClick}
                />
            ) : (
                <EarlyRepaymentCreate
                    earlyRepayment={earlyRepayment}
                    isDynamicPrice={isDynamicPrice}
                />
            )}
        </>
    )
}
