import { ExternalRateIndex } from '@dltru/dfa-models'
import moment, { Moment } from 'moment'
import FieldContext from 'rc-field-form/lib/FieldContext'
import { FC, useContext, useEffect, useState } from 'react'

import WrappedDateItem from '../../DateItem'
import { CardDivider } from '../../card'
import { WrappedInput } from '../../components/Form'
import { currentFloatNumber, dfaValidators, spreadNumber } from '../../utils'
import { IRedeemDateDfaProps, RedeemDateBlock } from './RedeemDateBlock'
import { RedeemPriceBlock } from './RedeemPriceBlock/RedeemPriceBlock'

interface IPriceSourceDynamicProps
    extends Pick<IRedeemDateDfaProps, 'settlementsType' | 'setRedeemDate'> {
    isEdit: boolean
    getExternalRate: (index: ExternalRateIndex) => void
    disabled: Record<string, boolean>
    isManual: boolean
    rateIndex: ExternalRateIndex
}

export const PriceSourceDynamic: FC<IPriceSourceDynamicProps> = ({
    isEdit,
    getExternalRate,
    disabled = {},
    isManual,
    rateIndex,
    ...propsForRedeemDate
}) => {
    const { getFieldValue, validateFields, isFieldTouched, setFieldsValue } =
        useContext(FieldContext)
    const [maxAmount, setMaxAmount] = useState(getFieldValue('max_supply_invested'))
    const [issueStartDate, setIssueStartDate] = useState<Moment | undefined>()
    const [issueEndDate, setIssueEndDate] = useState<Moment | undefined>()

    useEffect(() => {
        setIssueStartDate(getFieldValue('issue_start_date'))
        setIssueEndDate(getFieldValue('issue_end_date') ?? getFieldValue('issue_start_date'))
    }, [isEdit])

    const onChangeIssueStartDate = (value: Moment | null) => {
        setIssueStartDate(value ?? undefined)
        if (getFieldValue('issue_end_date')) {
            validateFields(['issue_end_date'])
        } else {
            setIssueEndDate(value ?? undefined)
            if (getFieldValue('redeem_date')) {
                validateFields(['redeem_date'])
            }
        }
    }

    const onChangeIssueEndDate = (value?: Moment | null) => {
        setIssueEndDate(value ?? undefined)
        if (getFieldValue('redeem_date')) {
            validateFields(['redeem_date'])
        }
    }

    useEffect(() => {
        getExternalRate(rateIndex)

        return () => {
            setFieldsValue({ price_external: undefined })
            setFieldsValue({ price_per_dfa: undefined })
        }
    }, [rateIndex])

    const onChangeMaxAmount = (event: React.ChangeEvent<HTMLInputElement>) => {
        setMaxAmount(event.target.value)
        if (isFieldTouched('min_supply_invested')) {
            validateFields(['min_supply_invested'])
        }
    }

    return (
        <>
            <div className="formRow">
                <WrappedInput
                    className="formComponentItem"
                    label="Цена актива на бирже, руб."
                    name="price_external"
                    placeholder="Цена единицы"
                    uneditable={isEdit}
                    disabled
                    required
                    rules={[{ validator: dfaValidators.price }]}
                />
            </div>
            <div className="formRow">
                <WrappedInput
                    className="formComponentItem"
                    label="Спред к цене выпуска, %"
                    name="investment_spread"
                    placeholder="Укажите спред к стоимости актива на бирже"
                    uneditable={isEdit}
                    required
                    rules={[{ validator: dfaValidators.spread }]}
                    normalize={spreadNumber}
                    disabled={disabled.investment_spread}
                />
            </div>
            <div className="formRow">
                <WrappedInput
                    className="formComponentItem"
                    label="Цена ЦФА, руб."
                    name="price_per_dfa"
                    placeholder="Цена единицы"
                    uneditable={isEdit}
                    required
                    disabled
                    rules={[{ validator: dfaValidators.price }]}
                    normalize={currentFloatNumber}
                />
            </div>
            <div className="formRow">
                <WrappedDateItem
                    className="formComponentItem"
                    label="Дата начала размещения ЦФА"
                    name="issue_start_date"
                    uneditable={isEdit}
                    required
                    rules={[{ validator: dfaValidators.issueStartDate }]}
                    showTime={{ defaultValue: moment('10:00', 'HH:mm') }}
                    onChange={onChangeIssueStartDate}
                    disabled={disabled.issue_start_date}
                />
            </div>
            {!isManual && (
                <div className="formRow">
                    <WrappedDateItem
                        className="formComponentItem"
                        label="Дата окончания размещения ЦФА"
                        name="issue_end_date"
                        uneditable={isEdit}
                        required
                        rules={[{ validator: dfaValidators.issueEndDate(issueStartDate) }]}
                        showTime={{ defaultValue: moment('20:00', 'HH:mm') }}
                        onChange={onChangeIssueEndDate}
                        disabled={disabled.issue_end_date}
                    />
                </div>
            )}
            <CardDivider />

            <div className="formRow">
                <WrappedInput
                    className="formComponentItem formComponentItem--xl"
                    label="Условие признания выпуска состоявшимся"
                    name="issue_conditions"
                    uneditable={isEdit}
                    multiline
                    required={isManual}
                    disabled={!isManual}
                />
            </div>
            <CardDivider />

            <div className="formRow">
                <RedeemPriceBlock uneditable={isEdit} disabled />
            </div>
            <div className="formRow">
                <WrappedInput
                    className="formComponentItem"
                    label="Спред к цене погашения, %"
                    name="redemption_spread"
                    placeholder="Укажите спред к стоимости актива на бирже"
                    uneditable={isEdit}
                    required
                    rules={[{ validator: dfaValidators.spread }]}
                    normalize={spreadNumber}
                    disabled={disabled.redemption_spread}
                />
            </div>
            <div className="formRow">
                <RedeemDateBlock
                    uneditable={isEdit}
                    dateForValidate={issueEndDate}
                    {...propsForRedeemDate}
                    disabled={disabled.redeem_date}
                />
            </div>
        </>
    )
}
