import { FC, useCallback, useContext, useEffect, useState } from 'react'
import { Checkbox, CheckboxChangeEvent } from '../../../Checkbox'
import { Form, WrappedInput } from '../../../components/Form'
import { Divider } from '../../../Divider'
import { WrappedRadioItem } from '../../../Radio'
import { checkBoxLabel, radioOptions } from './consts'

import FieldContext from 'rc-field-form/lib/FieldContext'
import styles from './EarlyRepayment.m.less'

interface IEarlyRepaymentCreateProps {
    earlyRepayment?: boolean
    isDynamicPrice: boolean
}

export const EarlyRepaymentCreate: FC<IEarlyRepaymentCreateProps> = ({
    earlyRepayment,
    isDynamicPrice,
}) => {
    const { getFieldsValue, setFieldsValue } = useContext(FieldContext)
    const [isShow, setIsShow] = useState(earlyRepayment)

    const onEarlyRepaymentChangeHandler = useCallback((e: CheckboxChangeEvent) => {
        setIsShow(e.target.checked)
    }, [])

    useEffect(() => {
        const values = getFieldsValue()
        const early_repayment_type =
            values.early_repayment_type !== undefined ? values.early_repayment_type : true
        setFieldsValue({ early_repayment_type })
    }, [])

    return (
        <div className={styles.container}>
            <Divider />
            <Form.Item name="is_early_repayment" valuePropName="checked">
                <Checkbox onChange={onEarlyRepaymentChangeHandler}>{checkBoxLabel}</Checkbox>
            </Form.Item>
            {isShow && (
                <div className={styles.extendedContainer}>
                    <WrappedInput
                        column
                        className="no-error-messages"
                        label="Срок погашения по обращению"
                        name="period_for_early_repayment"
                        placeholder="Укажите количество дней"
                        uneditable={false}
                        required
                    />
                    {isDynamicPrice && (
                        <WrappedInput
                            column
                            className="no-error-messages"
                            label="Спред"
                            name="early_redemption_spread"
                            placeholder=""
                            uneditable={false}
                            required
                        />
                    )}
                    <div className={styles.radioContainer}>
                        <WrappedRadioItem
                            column
                            label="Функция погашения будет доступна:"
                            id="early_repayment_type"
                            name="early_repayment_type"
                            uneditable={false}
                            options={radioOptions}
                        />
                    </div>
                </div>
            )}
        </div>
    )
}
