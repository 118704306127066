// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".mediumInput__Ambh {\n  margin-top: 4px !important;\n  width: 240px;\n}\n.largeInput_CNWN4 {\n  margin-top: 4px !important;\n  width: 460px;\n}\n.redeem_description_block_nLMly {\n  word-wrap: break-word;\n}\n", "",{"version":3,"sources":["webpack://./../../packages/@dfa-ui/src/templateComponents/dfaForm/RedeemPriceBlock/style.m.less"],"names":[],"mappings":"AAAA;EACI,0BAAA;EACA,YAAA;AACJ;AACA;EACI,0BAAA;EACA,YAAA;AACJ;AACA;EACE,qBAAA;AACF","sourcesContent":[".mediumInput {\n    margin-top: 4px !important;\n    width: 240px\n}\n.largeInput {\n    margin-top: 4px !important;\n    width: 460px\n}\n.redeem_description_block {\n  word-wrap: break-word;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"mediumInput": "mediumInput__Ambh",
	"largeInput": "largeInput_CNWN4",
	"redeem_description_block": "redeem_description_block_nLMly"
};
export default ___CSS_LOADER_EXPORT___;
